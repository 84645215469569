<template>
  <div class="mx-8">
    <v-subheader class="py-0 d-flex justify-space-between">
      <h3>USER GROUP</h3>
    </v-subheader>

    <div class="mb-2"></div>

    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="group"
          :items="groupOptions"
          outlined
          label="Filter"
          hide-details
          @change="init()"
        ></v-select>
      </v-col>
    </v-row>

    <div class="mb-4"></div>

    <v-data-table :headers="headers" :items="listUserGroup">
      <template v-slot:item.nomor="{ item }">
        <span>{{ listUserGroup.indexOf(item) + 1 }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn class="primary" small @click="toDetailPage(item._id)"
          >Lihat</v-btn
        >
      </template>

      <template v-slot:no-data>
        <label>Tidak ada data</label>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "No.", value: "nomor", width: "70px", sortable: false },
        { text: "Group", value: "group" },
        { text: "Sub Group", value: "subgroup" },
        { text: "Keterangan", value: "keterangan", sortable: false },
        { text: "Daftar Akses", value: "actions", sortable: false },
      ],
      group: "ALL",
      groupOptions: [
        { text: "ALL", value: "ALL" },
        { text: "PIMPINAN", value: "PIMPINAN" },
        { text: "OPERATOR", value: "OPERATOR" },
        { text: "ADMIN", value: "ADMIN" },
      ],
      params: "",
    };
  },
  computed: {
    listUserGroup() {
      return this.$store.getters.listUserGroup;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.group != "ALL") {
        this.params = {
          group: this.group,
        };
      }

      this.$store.dispatch("getListUserGroup", this.params);
    },
    toDetailPage(id) {
      this.$router.push({
        name: "d_usergroup",
        params: { id: id },
      });
    },
  },
};
</script>